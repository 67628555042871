.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  transition: all .5s;
  transform-origin: 0 0;
}

@media (min-width:720px) {
  .App {
    width: 100%;
    height: 98vh;
  }
}

@media (max-width:720px) {
  .App {
    transform: rotateZ(90deg) translateY(-100%);
    height: 95vw;
    width: 95vh;
  }
}

@media (max-width:540px) {
  .App {
    transform: rotateZ(90deg) translateY(-100%) scale(0.8);
    height: 95vw;
    width: 95vh;
  }
}

@font-face {
  font-family: 'hywenhei';
  src: url('./fonts/subset-HYWenHei-GEW.woff2');
}

.desc {
  font-family: 'hywenhei';
  color: gray;
}

.uid {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-family: 'hywenhei';
  color: gray;
}